.marquee-w {
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  top: 90%;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  overflow: hidden;
  background-color: bisque;
}

.footernote {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  background-color: #f0f0f0; /* Adjust as needed */
  padding: 10px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}

.section1_contianer {
  margin-top: 70px;
}
.bg-black {
  background: transparent;
}
.marquee {
  position: absolute;
  display: block;
  margin: auto auto;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100%;
  height: 100%;
}
.marquee span {
  display: inline-block;
  padding-left: 100%;
  font-family: "poppinsbold";
  text-align: center;
  /* -webkit-text-stroke: 1px #43e486; */
  white-space: nowrap;
  min-width: 100%;
  height: 100%;
  line-height: 50px;
  /* font-size: 85px; */
  animation: marquee 10s linear infinite;
  color: red;
}
.marquee2 span {
  animation-delay: 5s;
}
.tc-Footer {
  width: 100%;
  /* position: fixed;
  bottom: 0; */
  /* margin-bottom: 12px; */
  margin: 96px 0 0;
}

.footer-Text {
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  color: #828ea6;
}

.footer-Text span {
  font-weight: 600;
  color: #1b325e;
  cursor: pointer;
}

.floating_Footer {
  height: 64px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
}

.floating_Footer_Wrapper {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.floating_Footer_Left,
.floating_Footer_Right {
  width: 45%;
}

.floating_Footer_Center {
  width: 100%;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
