.footerContainer {
  background: #4a98f7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  max-width: 1920px;
  width: 100%;
  margin-top: 50px;
}

.footer_company_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-bottom: 1.5rem;
  height: 0px;
}

.footer_logo {
  cursor: pointer;
  padding-right: 0rem;
}

.about-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.about-heading {
  font-weight: bold;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.3rem;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.policy-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.policy-heading {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.3rem;
}

#mail-neg-margin a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.policy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.help-heading {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.3rem;
}

.help {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  color: white;
  justify-content: center;
}

.payment-method {
  display: flex;
  flex-direction: column;
}

.payment-cards {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  gap: 5px;
}

.pay-image {
  background-color: white;
  padding: 5px;
  border-radius: 10px;
  height: 22px !important;
  width: fit-content !important;
}

.contact_details {
  background: #ffcc28;
  padding: 24px 1rem;
}

.contact_heading {
  letter-spacing: -0.01em;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 18px;
}

.contact_link_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 1rem;
  color: white;
  text-align: start;
}

.contact_link {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.contact_link img {
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

.contact_link_text {
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

footer_last .new-con {
  display: flex;
  flex-direction: column;
}

.footer-items {
  /* font-family: "Quicksand"; */
  display: flex;
  font-weight: 700;
  flex-direction: row;
  padding: 0px 5.5rem;
  font-size: 13px;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem;
}

.footer_last {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

.footer_last > div > div {
  gap: 0.3rem;
}

.footer_last_heading {
  display: none;
}

.footer_last_heading-desk {
  display: none;
}

.social_links_wrapper {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  /* width: 150px; */
}

.social_links_wrapper img {
  max-width: 32px;
  max-height: 32px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.socialMedia-icon {
  margin-right: 0.5rem;
}

.vertical-div {
  border-left: 2px solid white;
  height: 130px;
  margin-right: 40px;
}

.tx-white {
  color: white;
}

.mail-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#mail-neg-margin {
  margin-top: -10px;
  margin-bottom: 8px;
}

.heading-2xl {
  font-size: 1.3rem;
  font-weight: 700;
  color: #5f5d5ddb;
  margin-bottom: 5px;
}

.customer-care {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer_terms_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer_terms_wrapper-desk a {
  text-decoration: none;
}

.footer_terms_column1,
.footer_terms_column2 {
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
}

.footer_terms {
  letter-spacing: -0.01em;
  color: #ebedf0;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  cursor: pointer;
  margin: 0;
}

.footer_terms_desk {
  font-family: "" Noto Sans Kannada ", Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: #ebedf0;
}

.last_contact_details {
  display: none;
}

.footer_copyright {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  margin: 2rem 0px 16px 0px;
}

.footer_copyright p {
  letter-spacing: 0.015em;
  color: #ebedf0;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin: 0;
}

.footerContainer-res a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.policy-item,
.about-item,
.help-item {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.footer-items a:hover {
  color: #ffffff;
}

@media screen and (min-width: 768px) {
  .footerContainer {
    height: fit-content;
    /* bottom: 0;
      position: absolute; */
  }

  .footer_details {
    font-size: 16px;
    line-height: 24px;
    max-width: 648px;
  }

  .footer_company_detail {
    padding: 1.5rem 10rem 3.75rem;
  }

  .contact_details {
    display: none;
  }

  .footer_terms_container {
    order: 1;
    flex: 1;
  }

  /* .footer_logo img {
      width: 130px;
    } */

  .social_links_container {
    order: 2;
    flex: 1;
  }

  .last_contact_details {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  #last_contact_details-sm-q {
    width: 220px;
  }

  .last_contact img {
    filter: invert(1);
  }

  .last_contact p {
    color: #ebedf0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
  }

  .footer_last_heading {
    display: block;
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin: 0 0 18px 0;
    /* border: 1px solid red; */
  }

  .footer_last_heading_desk {
    letter-spacing: -0.01em;
    color: #5f5d5ddb;
    line-height: 28px;
  }

  .footer_last_heading-desk {
    display: block;
    letter-spacing: -0.01em;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    margin: 0 0 18px 0;
  }

  #copy-right {
    font-size: 0.7rem;
  }

  .footer_terms {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.004em;
  }
}

@media screen and (min-width: 1024px) {
  .footer_last {
    padding: 0 0rem;
  }
}

@media screen and (min-width: 1400px) {
  .footer_last {
    padding: 0 0rem;
  }
}

.footerContainer-res {
  background: #1b325e;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  max-width: 1920px;
  width: 100%;
  flex-wrap: wrap;
  color: white;
  /* font-family: "Quicksand"; */
}

.company-detail-res {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem 1rem;
  justify-content: space-between;
}

.detail-left {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.detail-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.footer-details-bottom {
  display: flex;
  width: 100%;
  padding: 0rem 3rem;
}

.details-res {
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
  padding: 1rem;
}

.details-res span {
  font-size: 20px;
}

.footer-btm1 {
  width: 40%;
}

.footer-btm2 {
  width: 50%;
}

.footer-last-res {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 2rem;
}

.footer-pay {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.footer-payment {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.footer-payment-last {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.footer-payment-last img {
  width: 90%;
}

#ft-pay {
  margin-right: 5px;
}

.marginStyle {
  margin: 4px 0;
}

.footer-heading-2xl {
  font-size: 15px;
  font-weight: 700;
  color: #8d98ae;
  margin-bottom: 2px;
}

.footer-details-bottom {
  margin-top: -2rem;
}

.footer-logo-res {
  margin: 2rem 0rem 0rem 0rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo-res img {
  width: 45%;
}

.footer-copy-right {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.footer-copy-right span {
  font-size: 10px;
}

@media screen and (max-width: 468px) {
  .details-res span {
    font-size: 12px;
  }

  .marginStyle {
    margin: 6% 0;
  }

  .footer-copy-right span {
    font-size: 8px;
  }
}
